@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700");

.contentStyle {
  font-size: 1.3rem;
  padding-bottom: 1rem;
}

.contentStyleFooter {
  font-size: 0.8rem;
  padding-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.7rem;
}
.contentCenter {
  text-align: center;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.3em;
  color: #ffffff;
  padding: 0.7em;
}

.carouselContainer {
  position: relative;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-caption h1 {
  font-size: calc(100% + 3vw + 3vh);
  text-transform: uppercase;
  text-shadow: 1px 1px 15px #000;
}

.carousel-control-prev {
  height: 26%;
  top: 33%;
  width: 86px;
}
.carousel-control-next {
  height: 26%;
  top: 33%;
  width: 86px;
}

.fixedImg {
  height: 50vh;
}

.jumbotron {
  height: 20vh;
  background-color: #dadada;
  position: relative;
}

.lead {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 2em;
  font-weight: bold;
}

.mep-brand {
  background-color: #940e0d;
  padding: 0.5rem;
}

.row > * {
  padding: 1rem;
}

@media (max-width: 400px) {
  .carousel-caption h1 {
    font-size: calc(100% + 1.5vw + 1.5vh);
    text-transform: uppercase;
    text-shadow: 1px 1px 15px #000;
  }

  .carousel-caption {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .fixedImg {
    height: 40vh;
  }
}
